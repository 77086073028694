<template>
  <div class="Login:">
    <div class="Login:container">
      <div v-if="appStyle == 'stats'" class="Login:statsHeader">
        <img
          class="Login:logo_stats"
          src="~@/assets/images/general/sp-pro-dynamix-live-white.svg"
        />
      </div>
      <div class="Login:statsTitle">Login To Smart Live</div>
      <form class="Login:statsForm" action="">
        <div class="Login:statsInputBox">
          <label class="Login:statsLabel" for="email">Email address</label>
          <input
            class="Login:statsInput"
            type="text"
            id="email"
            placeholder="Enter your email address"
            v-model="user.email"
          />
        </div>
        <div class="Login:statsInputBox">
          <label class="Login:statsLabel" for="password">Password</label>
          <input
            class="Login:statsInput"
            type="password"
            id="password"
            placeholder="Enter your password"
            v-model="user.password"
          />
        </div>
        <div class="Login:error">{{ user.error }}</div>
        <input
          class="Login:statsSubmit"
          type="submit"
          value="LOG IN"
          @click="Submit"
        />
      </form>
      <img
        v-if="appStyle != 'stats'"
        class="Login:image-one"
        src="~@/assets/images/login/xo.png"
      />
      <img
        v-if="appStyle != 'stats'"
        class="Login:image-two"
        src="~@/assets/images/login/Player_white.png"
      />
      <img
        v-if="appStyle != 'stats'"
        class="Login:image-three"
        src="~@/assets/images/login/away@3x.png"
      />
      <img
        v-if="appStyle != 'stats'"
        class="Login:image-four"
        src="~@/assets/images/login/home@3x.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
        error: "",
      },
    };
  },
  props: {
    msg: String,
  },

  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    Login() {
      var vm = this;

      this.$store
        .dispatch("retrieveToken", {
          email: this.user.email,
          password: this.user.password,
        })
        .then((response) => {
          if (response.status == 200) {
            // Add Cookie For SSO
            vm.$cookies.set(
              "smart_live_access_token",
              response.data.user.email + "|"  + response.data.token,
              "14400s"
            );
            // Routing Based On Permission
            const permissions = this.$store.state.user.user.permissions;
            if (
              permissions.includes("LIVE_ANALYSIS") &&
              permissions.includes("POST_ANALYSIS")
            ) {
              this.$router.push("/selecttype");
            } else if (permissions.includes("LIVE_ANALYSIS")) {
              this.$router.push("/live");
            } else if (permissions.includes("POSTDATA_CONSOLE")) {
              this.$router.push("/postdataconsole");
            } else this.$router.push("/post");
          }
        })
        .catch((error) => {
          // console.log(error.response.status);
          // console.log(error.response.data);
          console.log(error.message);
          const message = error.response.data.message;
          this.user.error = message;
        });
    },
    Submit(e) {
      e.preventDefault();
      if (this.user.username != "" && this.user.password != "") {
        this.Login();
      } else {
        console.log("feed the form");
      }
    },
  },
  computed: {
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  mounted() {},
  created() {
    if (this.msg == undefined || this.msg == "") {
      // Se loggato verifico il token
      if (this.loggedIn()) {
        // Check if token is still valid
        const userid = {
          userid: this.$store.state.user.user.id,
        };
        this.$store
          .dispatch("checkToken", userid)
          .then((res) => {
            if (res.status != 200) this.$router.push("/login");
            else {
              // Routing Based On Permission
              const permissions = this.$store.state.user.user.permissions;
              if (
                permissions.includes("LIVE_ANALYSIS") &&
                permissions.includes("POST_ANALYSIS")
              ) {
                this.$router.push("/selecttype");
              } else if (permissions.includes("LIVE_ANALYSIS")) {
                this.$router.push("/live");
              } else this.$router.push("/post");
            }
          })
          .catch((err) => {
            this.user.error = "Unauthorized Access. Please login";
          });
      }
    } else {
      this.user.error = this.msg;
    }
  },
};
</script>

<style lang="scss" scoped>
.Login\: {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &container {
    width: 400px;
    position: relative;
    border: var(--login-external-border);
  }

  &title {
    font-weight: bold;
    text-align: center;
    font-size: 35px;
    margin-bottom: 50px;
  }

  &error {
    color: red;
    width: 100%;
    margin: 24px 150px 22px 24px;
  }

  &image {
    &-one,
    &-two,
    &-three,
    &-four {
      position: absolute;
    }
    &-one {
      bottom: 10px;
      right: -100px;
      width: 200px;
    }
    &-two {
      width: 200px;
      top: -70px;
      right: -50px;
    }
    &-three,
    &-four {
      width: 200px;
      top: -0px;
      right: -100px;
    }
    &-three {
      top: 60px;
      right: -170px;
    }
  }

  &field {
    margin: 15px 0;
  }

  form {
    position: relative;
    z-index: 10;
  }

  input {
    border: 1px solid #fff;
    color: white;

    &:focus {
      border-radius: 0px;
      outline: none;
    }

    &[type="text"],
    &[type="password"] {
      padding: 10px;
      width: var(--login-input-width);
      background-color: transparent;
      border: var(--login-input-border);
      color: var(--color);
    }
    &[type="submit"] {
      margin-top: 30px;
      padding: 10px 20px;
      text-transform: uppercase;
      float: right;
      background-color: white;
      color: white;
    }
  }
  &statsHeader {
    width: 100%;
    height: 90px;
    margin: 0 0 24px;
    padding: 21px 108px;
    border-radius: 4px;
    background-image: linear-gradient(
      283deg,
      #faa51a 0%,
      #f06424 36%,
      #e5202f 67%,
      #9e07ae 100%
    );
    //background-image: linear-gradient(283deg, #faa51a 100%, #f06424 67%, #e5202f 36%, #9e07ae 0%);
  }
  &statsTitle {
    width: 226px;
    height: 24px;
    margin: 24px 150px 22px 24px;
    font-size: 21px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--color);
  }
  &statsLabel {
    width: 65px;
    height: 16px;
    margin: 0 287px 2px 0;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: var(--color);
  }
  &statsInput {
    width: 154px;
    height: 24px;
    margin: 2px 198px 6px 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--color);
    border: var(--login-border);
  }
  &statsInputBox {
    width: 352px;
    height: 50px;
    margin: 22px 24px 32px;
  }
  &statsForm {
    width: 400px;
  }
  &statsSubmit {
    width: 144px;
    height: 32px;
    margin: 32px 128px 12px;
    padding: 8px 49px 8px 50px;
    border-radius: 20px;
    background-image: linear-gradient(257deg, #f06424, #e5202f 5%);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
  }
}
</style>
