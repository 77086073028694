var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Login:" }, [
    _c("div", { staticClass: "Login:container" }, [
      _vm.appStyle == "stats"
        ? _c("div", { staticClass: "Login:statsHeader" }, [
            _c("img", {
              staticClass: "Login:logo_stats",
              attrs: {
                src: require("@/assets/images/general/sp-pro-dynamix-live-white.svg")
              }
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "Login:statsTitle" }, [
        _vm._v("Login To Smart Live")
      ]),
      _c("form", { staticClass: "Login:statsForm", attrs: { action: "" } }, [
        _c("div", { staticClass: "Login:statsInputBox" }, [
          _c(
            "label",
            { staticClass: "Login:statsLabel", attrs: { for: "email" } },
            [_vm._v("Email address")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.email,
                expression: "user.email"
              }
            ],
            staticClass: "Login:statsInput",
            attrs: {
              type: "text",
              id: "email",
              placeholder: "Enter your email address"
            },
            domProps: { value: _vm.user.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "email", $event.target.value)
              }
            }
          })
        ]),
        _c("div", { staticClass: "Login:statsInputBox" }, [
          _c(
            "label",
            { staticClass: "Login:statsLabel", attrs: { for: "password" } },
            [_vm._v("Password")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.password,
                expression: "user.password"
              }
            ],
            staticClass: "Login:statsInput",
            attrs: {
              type: "password",
              id: "password",
              placeholder: "Enter your password"
            },
            domProps: { value: _vm.user.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "password", $event.target.value)
              }
            }
          })
        ]),
        _c("div", { staticClass: "Login:error" }, [
          _vm._v(_vm._s(_vm.user.error))
        ]),
        _c("input", {
          staticClass: "Login:statsSubmit",
          attrs: { type: "submit", value: "LOG IN" },
          on: { click: _vm.Submit }
        })
      ]),
      _vm.appStyle != "stats"
        ? _c("img", {
            staticClass: "Login:image-one",
            attrs: { src: require("@/assets/images/login/xo.png") }
          })
        : _vm._e(),
      _vm.appStyle != "stats"
        ? _c("img", {
            staticClass: "Login:image-two",
            attrs: { src: require("@/assets/images/login/Player_white.png") }
          })
        : _vm._e(),
      _vm.appStyle != "stats"
        ? _c("img", {
            staticClass: "Login:image-three",
            attrs: { src: require("@/assets/images/login/away@3x.png") }
          })
        : _vm._e(),
      _vm.appStyle != "stats"
        ? _c("img", {
            staticClass: "Login:image-four",
            attrs: { src: require("@/assets/images/login/home@3x.png") }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }